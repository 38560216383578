import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebaseui/dist/firebaseui.css'
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import MobileNavbar from "./MobileNavbar";


function Account() {
    const {id} = useParams();

    useEffect(() => {

    });



    return (
        <div>
            <h1>Account</h1>

            <MobileNavbar id={id} />
        </div>
    );
}


export default Account;

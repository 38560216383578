import {useEffect, useState} from "react";
import useApi from "./useApi";

const useApiData = (requestType, params = {}, default_data = null) => {
    const {data, loadData, loading, error} = useApi(requestType, params, default_data);

    useEffect(() => {
        if (data || loading || error) {
            return
        }

        loadData()
    }, [loadData, requestType, params]);

    return { data, loading, error, loadData };
};

export default useApiData;

import {useParams} from "react-router-dom";
import useApiRequest from "../services/useApi";
import useMobileRepo from "../services/useMobileRepo";
import MobileNavbar from "./MobileNavbar";


function CheckboxSetting(props) {

    const params = { mobileId: props.mobile.id, key: props.keyName };

    const { loadData: updateSetting } = useApiRequest("updateSetting", params);

    return (
        <div className="form-check form-switch">
            <label className="form-check-label" htmlFor={"flexSwitchCheckDefault" + props.keyName}>
                <input className="form-check-input" type="checkbox" role="switch"
                       id={"flexSwitchCheckDefault" + props.keyName}
                       defaultChecked={props.mobile.settings[props.keyName] === "true"}
                       onChange={event => updateSetting({value: event.target.checked})}
                />
                {props.caption}</label>
        </div>
    );
}

function SelectSetting(props) {
    const params = {mobileId: props.mobile.id, key: props.keyName };

    const { loadData: updateSetting } = useApiRequest("updateSetting", params);

    return (
        <div>
            <label htmlFor={"input" + props.keyName} className="form-label">{props.caption}</label>
            <select defaultValue={props.mobile.settings[props.keyName]}
                    onChange={event => updateSetting({value: event.target.value})}
                    className="form-control"
                    id={"input" + props.keyName}
            >
                {props.options.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
            </select>
        </div>
            );
}

function TextSetting(props) {
    const params = {mobileId: props.mobile.id, key: props.keyName };

    const { loadData: updateSetting } = useApiRequest("updateSetting", params);

    return (
        <div>
            <label htmlFor={"input" + props.keyName} className="form-label">{props.caption}</label>
            <input type="text" defaultValue={props.mobile.settings[props.keyName]}
                   onChange={event => updateSetting({value: event.target.value})}
                   className="form-control"
                   id={"input" + props.keyName}
            />
        </div>
    );
}

function MobileSettings() {
    const {id} = useParams();
    const {mobile} = useMobileRepo(id);

    const { loadData: deleteMobile } = useApiRequest("hideMobile", {mobileId: id});

    return (
        <div>
                    {mobile && (
                        <div>
                            <h3>Device: {mobile.settings.nickname}, {mobile.deviceName}</h3>

                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                        <CheckboxSetting
                                            mobile={mobile}
                                            keyName={"allowScreenShots"}
                                            caption="Allow screenshots"
                                        />
                                </li>
                                <li className="list-group-item">
                                        <CheckboxSetting
                                            mobile={mobile}
                                            keyName={"pictureOnScreenOn"}
                                            caption="Take picture on screen on"
                                        />
                                </li>
                                <li className="list-group-item">
                                        <CheckboxSetting
                                            mobile={mobile}
                                            keyName={"takePictureAfterScreenOn"}
                                            caption="Take picture after screen on"
                                        />
                                </li>
                                <li className="list-group-item">
                                        <CheckboxSetting
                                            mobile={mobile}
                                            keyName={"stopAudioRecordingOnScreenOn"}
                                            caption="Stop audio recording on screen on"
                                        />
                                </li>
                                <li className="list-group-item">
                                        <SelectSetting
                                            mobile={mobile} keyName={"recordingDuration"}
                                            caption="Audio records length"
                                            options={[
                                                {value: "1", label: "1 minute"},
                                                {value: "5", label: "5 minutes"},
                                                {value: "10", label: "10 minutes"},
                                                {value: "20", label: "20 minutes"},
                                            ]}/>
                                </li>
                                <li className="list-group-item">
                                        <TextSetting
                                            mobile={mobile}
                                            keyName={"nickname"}
                                            caption="Device nickname"
                                        />
                                </li>
                                <li className="list-group-item">
                                    {mobile.hidden ?
                                        <button className="btn btn-outline-success" onClick={() => deleteMobile({hide: false})}><i className="bi bi-eye"></i> Show device</button>
                                        : <button className="btn btn-danger" onClick={() => deleteMobile({hide: true})}><i className="bi bi-x-lg"></i> Delete device</button>
                                    }
                                </li>
                            </ul>
                        </div>
                    )}
        <MobileNavbar id={id} />
    </div>
    );
}


export default MobileSettings;
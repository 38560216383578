// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAT92XwmYAOlPQ3FVYU_dmX41YVb2CZynw",
    authDomain: "advancedhelper-7c76f.firebaseapp.com",
    projectId: "advancedhelper-7c76f",
    storageBucket: "advancedhelper-7c76f.appspot.com",
    messagingSenderId: "190295916580",
    appId: "1:190295916580:web:ce88f8a5c8e7cd45f898b1",
    measurementId: "G-Y2HHREXRS8"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export {
    auth,
    app,
}

import React from 'react';
import { NavLink } from 'react-router-dom';
import useMobilesRepo from "../services/useMobilesRepo";

function MobileNavbar({ id }) {
    id = id || localStorage.getItem('mobileId');
    const {mobiles} = useMobilesRepo();


    return (
        <nav className="navbar navbar-dark navbar-bottom">
            <div className="container-fluid">
                <ul className="nav justify-content-around w-100">

                    <li className="nav-item">
                        <div className="btn-group dropup">

                            <ul className="dropdown-menu">
                                {mobiles.map(mobile => (
                                    <li key={mobile.id}>
                                        <NavLink to={`/mobile/${mobile.id}`}
                                                 onClick={() => {
                                                     localStorage.setItem('mobileId', mobile.id);
                                                 }}
                                                 className="dropdown-item">{mobile.settings.nickname}, {mobile.deviceName}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>


                            <NavLink to={`/account`} className="nav-link" data-bs-toggle="dropdown">
                                <i className="bi bi-phone"></i>
                            </NavLink>
                        </div>
                    </li>

                    <li className="nav-item">
                        <NavLink to={`/mobile/${id}/map`} className={"nav-link " + (id ? '' : 'disabled')}>
                            <i className="bi bi-map"></i>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={`/mobile/${id}/settings`} className={"nav-link " + (id ? '' : 'disabled')}>
                            <i className="bi bi-gear"></i>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={`/mobile/${id}/events`} className={"nav-link " + (id ? '' : 'disabled')}>
                            <i className="bi bi-card-list"></i>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={`/mobile/${id}/commands`} className="nav-link">
                            <i className="bi bi-lightning"></i>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to={`/account`} className="nav-link">
                            <i className="bi bi-person"></i>
                        </NavLink>
                    </li>

                </ul>
            </div>
        </nav>
    );
}

export default MobileNavbar;
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import {useEffect} from "react";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import AuthService from "../services/AuthService";

const Auth = () => {
    useEffect(() => {
        // FirebaseUI config.
        let uiConfig = {
            callbacks: {
                signInSuccessWithAuthResult: function(authResult, redirectUrl) {
                    const anonymousUser = firebase.auth().currentUser;

                    // User successfully signed in.
                    // Return type determines whether we continue the redirect automatically
                    // or whether we leave that to developer to handle.
                    return true;
                },
                uiShown: function() {
                    // The widget is rendered.
                    // Hide the loader.
                    // document.getElementById('loader').style.display = 'none';
                }
            },
            // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
            signInFlow: 'popup',
            signInSuccessUrl: '/mobiles',
            signInOptions: [
                {
                    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    requireDisplayName: false,
                    signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
                },
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            ],
            // Other config options...
        }

        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());

        // Check if FirebaseUI instance already exists
        if (!ui.isPendingRedirect()) {
            ui.start('#firebaseui-auth-container', uiConfig);
        }

        // Cleanup function to prevent memory leaks
        return () => {
            ui.reset();
        };
    }, []);

    return (
        <div>
            <div id="firebaseui-auth-container"></div>
        </div>
    );
};

function Login() {
    return (
        <Auth/>
    );
}

export default Login;

import {useCallback, useState} from "react";
import {Container} from "./Container";
import useAuth from "./useAuth";

const container = new Container()

const useApiRequest = (requestType, params = {}, default_data = null) => {
    const { token } = useAuth();

    const [data, setData] = useState(default_data);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const loadData = useCallback(async (requestParams = {}) => {
        if (!token) return;

        params = { ...params, ...requestParams}

        setLoading(true);
        const api = container.getApi(token);
        try {
            const result = await api.sendRequest(requestType, params);
            setData(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }, [token, requestType, params]);

    return { data, loading, error, loadData };
};

export default useApiRequest;

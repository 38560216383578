import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebaseui/dist/firebaseui.css'
import {Link} from "react-router-dom";
import useAuth from "../services/useAuth";


function Account() {
    const {user} = useAuth();

    if (user) {
        window.location.href = '/mobiles';
        return null;
    }

    return (
        <div className="p-2">
            <h1>Please login to continue</h1>
            <Link to="/login">Login</Link>
        </div>
    );
}


export default Account;

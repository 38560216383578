import {useParams} from "react-router-dom";
import useApiData from "../services/useApiData";

function Invoice() {
    const { id } = useParams();
    const { data, loading, error } = useApiData("getData", { invoiceId: id });

    return (
        <div className="container my-5">
            <h2 className="text-center mb-5">Invoice</h2>

            {loading && <div className="text-center my-4">Loading data...</div>}

            {error && <div className="alert alert-danger text-center">{error.message}</div>}

            {data && data.invoice && (
                <div>
                    <h3>Invoice #{data.invoice.id}</h3>
                    <div>Amount: {data.invoice.amount}</div>
                    <div>Date: {data.invoice.date}</div>
                    <div>Status: {data.invoice.status}</div>
                </div>
            )}
        </div>
    );
}

export default Invoice;
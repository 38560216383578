import {collection, getDocs, getFirestore, query, where, orderBy, limit} from "firebase/firestore";
import firebase from "firebase/compat/app";

const firebaseConfig = {
    apiKey: "AIzaSyAT92XwmYAOlPQ3FVYU_dmX41YVb2CZynw",
    authDomain: "advancedhelper-7c76f.firebaseapp.com",
    projectId: "advancedhelper-7c76f",
    storageBucket: "advancedhelper-7c76f.appspot.com",
    messagingSenderId: "190295916580",
    appId: "1:190295916580:web:ce88f8a5c8e7cd45f898b1",
    measurementId: "G-Y2HHREXRS8"
};

const app = firebase.initializeApp(firebaseConfig);
const db = getFirestore(app);

class AbstractTable {
    currentQuery = null;

    get collectionName() {
        throw new Error("You have to implement the method collectionName!");
    }

    tableRef() {
        return collection(db, this.collectionName);
    }

    queryBuilder() {
        this.currentQuery = query(this.tableRef())
        return this
    }

    where(field, operator, value) {
        this.currentQuery = query(this.currentQuery, where(field, operator, value))
        return this
    }

    whereEq(field, value) {
        return this.where(field, "==", value)
    }

    limit(limits) {
        this.currentQuery = query(this.currentQuery, limit(limits))
        return this
    }

    orderBy(field, direction = "asc") {
        this.currentQuery = query(this.currentQuery, orderBy(field, direction))
        return this
    }

    orderByDesc(field) {
        return this.orderBy(field, "desc")
    }

    async build() {
        return (await getDocs(this.currentQuery)).docs.map(this.processDoc)
    }

    processDoc(doc) {
        return doc.data()
    }
}

export default AbstractTable;

import {app} from "./firebaseConfig";
import {collection, enableIndexedDbPersistence, getFirestore, onSnapshot, query, where, doc, getDoc, limit} from "firebase/firestore";

const db = getFirestore(app);

// Enable offline persistence
enableIndexedDbPersistence(db).catch(console.error);

class FirebaseCollection {
    constructor(uid) {
        this.uid = uid
    }

    getCollection(collectionName) {
        this.collectionName = collectionName;
        this.query = collection(db, collectionName);
        if (this.uid) {
            this.where("uid", this.uid);
        }
        return this;
    }

    where(name, value, operator = "==") {
        this.query = query(this.query, where(name, operator, value));
        return this;
    }

    whereNot(name, value) {
        this.query = query(this.query, where(name, "!=", value));
        return this;
    }

    listenForUpdates(setData) {
        return onSnapshot(this.query, querySnapshot => {
            const dataArray = [];
            querySnapshot.forEach(doc => {
                dataArray.push(this.processDoc(doc));
            });
            setData(dataArray);  // Update the state with the new data
        });
    }

    async getDocument(id) {
        const docSnap = await getDoc(doc(db, this.collectionName, id));
        if (docSnap.exists()) {
            return this.processDoc(docSnap);
        } else {
            throw Error(`Document ${this.collectionName}:${id} not found!`);
        }
    }

    listenForDocumentUpdates(id, setData) {
        const docRef = doc(db, this.collectionName, id); // Reference to the document
        return onSnapshot(docRef, (docSnap) => {
            if (docSnap.exists()) {
                setData({ id: docSnap.id, ...docSnap.data() }); // Update the data for the document
            } else {
                console.error(`Document ${this.collectionName}:${id} does not exist!`);
            }
        });
    }

    limit(limits) {
        this.query = query(this.query, limit(limits))
        return this
    }

    orderBy(field, direction = "asc") {
        this.query = this.query.orderBy(field, direction)
        return this
    }

    processDoc(doc) {
        return {id: doc.id, ...doc.data()}
    }

}

export default FirebaseCollection;

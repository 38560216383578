import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebaseui/dist/firebaseui.css'
import {useEffect, useState} from "react";
import {QRCodeSVG} from 'qrcode.react';

import {NavLink} from "react-router-dom";

import useApiData from "../services/useApiData";

import 'bootstrap-icons/font/bootstrap-icons.css';
import useMobilesRepo from "../services/useMobilesRepo";
import {createRoot} from "react-dom/client";
import useAuth from "../services/useAuth";


function Mobiles() {
    const { data: userCode } = useApiData("getUserCode");
    const {user} = useAuth();
    const {mobiles} = useMobilesRepo();

    const handleRowClick = (url) => {
        window.location.href = url;
    };

    const handleShare = () => {
        navigator.share({
            title: 'Web Share API',
            text: 'Check out Web Share API',
            url: 'https://safekid.app?email=' + user?.email,
        })
            .then(console.log)
            .catch(console.error)
    }

    useEffect(() => {
        const container = document.getElementById('qrcode');
        const root = createRoot(container);
        root.render(<QRCodeSVG value={"https://SafeKid.app?email=" + user?.email} />);
    })

    return (
        <div>
            <table className="table">
                <tbody>
                {mobiles.map(mobile => (
                    <tr key={mobile.id} className="click">
                        <td>
                            <div onClick={() => handleRowClick(`/mobile/${mobile.id}`)}>
                            <b>{mobile.settings.nickname}'s {mobile.deviceName}</b> <span
                                className={"badge " + (mobile.newRecords > 0 ? "text-bg-success" : "text-bg-secondary")}>{mobile.newRecords}
                            </span>
                            <br/>
                            Battery: {mobile.state.batteryLevel}% / {mobile.state.lastConnection ? new Date(Number(mobile.state.lastConnection)).toLocaleString() : '-'}
                            </div>
                            <br/>
                        <Plan mobile={mobile}/> <NavLink to={`/activate/${mobile.id}`}>
                            Activate
                        </NavLink>
                        </td>
                    </tr>
                ))}
                <tr>
                <td>
                <p className="d-inline-flex gap-1">
                    <button className="btn btn-primary" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                        <i className="bi bi-person-plus"></i> &nbsp;
                        Add Kids Device
                    </button>
                </p>
                <div className="collapse" id="collapseExample">
                    <div className="card card-body">
                        <p>To add your child's device, please choose one of the following options:</p>

                        Share the link with your child's device:
                                <button id="shareBtn" className="btn btn-secondary" onClick={handleShare}>
                                    <i className="bi bi-share"></i> &nbsp;
                                    Share the link
                                </button>
                            <hr/>
                            Have them open the webpage <b><a
                                href={"https://safekid.app?email=" + user?.email}>SafeKid.app</a></b> on their device.
                        <hr/>
                            Scan the QR code below using their mobile device <div id="qrcode"></div>

                    </div>
                </div>
                </td>
                </tr>
            </tbody>
        </table>

</div>
)
    ;
}

function Plan({mobile}) {
    const plan = mobile.plan
    const active = plan.expired ? "Expired" : (plan.recurring ? "Active" : new Date(plan.endsAt).toLocaleString())
    return (
        <span>
            <NavLink to={`/details/${mobile.id}`}>{plan.type} : {active}</NavLink>.
        </span>
    )
}

export default Mobiles;

import config from "../../config";
import {FunctionsApi} from "./FunctionsApi";

class Container {
    get api() {
        console.log(this.token)
        return new FunctionsApi(config.apiUrl, this.token)
    }

    getApi(token) {
        if (!token) {
            return this.noTokenError()
        }

        return new FunctionsApi(config.apiUrl, token)
    }

    get token() {
        return localStorage.getItem('token') || this.noTokenError()
    }

    setToken(token) {
        localStorage.setItem('token', token)
    }

    setEmail(email) {
        localStorage.setItem('email', email)
    }

    get email() {
        return localStorage.getItem('email')
    }

    noTokenError() {
        throw new NoTokenError()
    }

    logout() {
        localStorage.removeItem('token')
        localStorage.removeItem('email')


    }
}

class NoTokenError extends Error {}

export {
    Container
}

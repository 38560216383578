import FirebaseCollection from "./FirebaseCollection";
import {useEffect, useState} from "react";
import useAuth from "./useAuth";

function getLocalDocument(id) {
    return JSON.parse(localStorage.getItem(id))
}

const useMobileRepo = (id) => {
    const [mobile, setMobile] = useState(getLocalDocument(id));
    const {user} = useAuth()

    useEffect(() => {
            if (!user || !user.uid) {
                return
            }

            const collection = new FirebaseCollection(user.uid).getCollection("view_mobiles");

            return collection.listenForDocumentUpdates(id, (data) => {
                localStorage.setItem(id, JSON.stringify(data))
                setMobile(data)
            })
    }, [user, id])

    return {mobile}
}

export default useMobileRepo;

import {Container} from "./Container";

const container = new Container();

class AuthService {
    async setUser(user) {
        this.user = user;
        container.setToken(await user.getIdToken())
        container.setEmail(user.email)
    }

    getToken() {
        return container.token
    }
}

export default AuthService;
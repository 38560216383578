import React, {useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import useApiData from "../services/useApiData";
import useApiRequest from "../services/useApi";
import '../../css/custom.css';
import MobileNavbar from "./MobileNavbar";

function Activate() {
    const { id } = useParams();
    const { data, loading, error } = useApiData("getActivation", { mobileId: id });

    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectedPayProvider, setSelectedPayProvider] = useState(null);
    const [recurring, setRecurring] = useState(false);
    const [mons, setMons] = useState(1);

    const params = {
        mobileId: id,
        planType: selectedPlan,
        recurring: recurring,
        payType: selectedPayProvider,
        redirect: `${window.location.origin}/account`,
        mons: mons,
    };

    const { data: activateResult, loadData: activatePlan, loading: activateLoading } = useApiRequest("activatePlan", params);

    useEffect(() => {
        if (activateResult && activateResult.redirect) {
            window.location.href = activateResult.redirect;
        }
    }, [activateResult]);

    if (!data || loading) {
        return <div className="text-center my-4">Loading data...</div>;
    }

    if (error) {
        return <div className="alert alert-danger text-center">{error.message}</div>;
    }

    const mobile = data.mobile;
    const plans = data.plans;
    const payProviders = data.payProviders;

    return (
        <div>
        <div className="container my-5">
            <div className="row">
                <div className="col-md-4">
                    <h3>Select plan</h3>
                </div>
            </div>

            <div className="row plans">

                {plans.map((planInfo) => <div className="col-md-4 plan-div" onClick={() => setSelectedPlan(planInfo.plan.type)}>
                    <div className={"card " + (selectedPlan === planInfo.plan.type ? "selected" : "")}>
                        <h5 className="card-header">
                            {planInfo.plan.title}
                        </h5>
                        <div className="card-body">
                            {planInfo.plan.description}
                            <ul>
                                {planInfo.plan.features.map((feature, index) => <li key={index}>{feature}</li>)}
                            </ul>

                            <b>${planInfo.price / 100}</b> / mon
                        </div>
                    </div>
                </div>)}

                {selectedPlan && <div className="row order_page">
                    <div className="col-md-8">
                        <div className="payment-methods">
                            <div className="accordion" id="accordionExample">

                                {payProviders.map(provider => <PayProvider
                                    mobileId={mobile.id}
                                    payProvider={provider}
                                    selectedPlan={selectedPlan}
                                    />
                                )}

                            </div>
                        </div>
                    </div>

                </div>}

                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <img src="/img/money-back-guarantee-256.png" height="128" alt=""/>
                        <img src="/img/secure-payment-png-256.png" height="128" alt=""/>
                    </div>
                    <p className="col-md-8 col-sm-12">
                        <i className="bi bi-check text-success"/> 100% satisfied guaranteed or money back.<br/>
                        <i className="bi bi-check text-success"/> All transactions are secured with SSL protocol.
                    </p>
                </div>


            </div>
        </div>
            <MobileNavbar id={id}/>
        </div>
    );
}

function subscribe(type, autopay, mon) {
    console.log(type, autopay, mon);
}

function PayProvider({mobileId, payProvider, selectedPlan}) {
    const [expanded, setExpanded] = useState(false);
    const [autopay, setAutopay] = useState(true);

    const params = {
        mobileId: mobileId,
        planType: selectedPlan,
        recurring: autopay,
        payType: payProvider.type,
        redirect: `${window.location.origin}/account`,
        mons: 1,
    };

    const { data: activateResult, loadData: activatePlan, loading: activateLoading } = useApiRequest("activatePlan", params);

    useEffect(() => {
        if (activateResult && activateResult.status === 'ok') {
            if (activateResult.redirect) {
                window.location.href = activateResult.redirect;
            }
        }
    }, [activateResult]);

    const handleSelect = () => {
        setExpanded(true);
        activatePlan().catch(console.error)
    };

    const provider = payProvider;

    const mon = 1;

    const payTypeIcons = {
        'card': <i className="bi bi-credit-card"></i>,
        'paypal': <i className="bi bi-paypal"></i>,
        'bitcoin': <i className="bi bi-currency-bitcoin"></i>,
    }

    return (
        <div className="accordion-item">
            <div className="accordion-header">
                <h4 className="accordion-header" id={"heading" + provider.type}>
                    <button className="accordion-button" type="button"
                            data-bs-toggle="collapse" data-bs-target={"#collapse" + provider.type}
                            aria-expanded="true" aria-controls={"collapse" + provider.type}>
                        {provider.name}
                        <div className="paymethod-row">
                            {provider.supportTypes.map((payment, index) => <div key={index}>
                                {payTypeIcons[payment]}
                            </div>)}
                        </div>
                    </button>
                </h4>
            </div>

            <div id={"collapse" + provider.type} className="accordion-collapse collapse"
                 aria-labelledby={"heading" + provider.type} data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    <div className="checkbox">
                        <label>
                            <input type="checkbox" checked={autopay} onClick={event => setAutopay(event.target.checked)} /> Enable auto-pay. Cancel anytime.</label>
                    </div>


                    {!autopay && <div>
                        <span>
                            Monthly payments:
                            <span
                                className="order-total-value pull-right"><strong>${selectedPlan.price / 100}</strong></span>
                        </span>

                    <span className="prices">
                                    <div ng-repeat="(month, price) in planSelected.prices"
                                         className="panel panel-default"
                                         ng-click="selectMon(month, price)"
                                         ng-class="{'panel-success': mon == month}"
                                         style={{cursor: "pointer", margin: "3px 0", padding: "4px"}}>
                                        month mon: <strong>$price</strong><span
                                        ng-if="price < planSelected.basePrice * month">, save ${}
                                    </span>
                                    </div>

                                    Order Total:
                                    <span
                                        className="order-total-value pull-right"><strong>$total</strong></span><br/>
                                    <small>
                                        Your plan will be active till ...
                                    </small>
                                </span>
                    </div>}

                    <button
                            className="btn btn-success btn-lg"
                            style={{margin: "15px 0"}}
                            onClick={handleSelect}
                    >
                        {activateLoading && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                        Continue to {provider.name}
                    </button>

                    <p className="small" style={{opacity: "0.85", margin: "0"}}>By continuing,
                        you
                        agree to our <a href="/info/terms" target="_blank">terms and conditions</a>, <a href="/info/policy" target="_blank">privacy policy</a></p>
                </div>
            </div>
        </div>
    );
}

export default Activate;

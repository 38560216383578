const dev = {
    apiUrl: 'http://localhost:8080/'
};

const prod = {
    apiUrl: 'https://us-east4-advancedhelper-7c76f.cloudfunctions.net/family-shield-api'
};

const config = process.env.REACT_APP_STAGE === 'dev'
    ? dev
    : prod;

console.log(process.env)

export default {
    stage: process.env.REACT_APP_STAGE,
    ...config
};


import {NavLink, useParams} from "react-router-dom";
import useMobileRepo from "../services/useMobileRepo";
import MobileNavbar from "./MobileNavbar";
import useLocationsRepo from "../services/useLocationsRepo";

function googleMapRender(locations) {
    const key = "AIzaSyC1RJ5gNqRHkRWmFm6ZeRAJ5fBnNCdKKY8"

    if (!locations || locations.length === 0) {
        return `https://maps.googleapis.com/maps/api/staticmap?center=0,0&zoom=1&size=400x400&key=${key}`;
        }

    let mapLink = `https://maps.googleapis.com/maps/api/staticmap?center=${locations[0].latitude},${locations[0].longitude}&zoom=12&size=400x400&key=${key}`;
    locations.forEach(location => {
        if (location.latitude) {
            mapLink += `&markers=color:red%7Clabel:${location.label}%7C${location.latitude},${location.longitude}`;
        }
    });
    return mapLink;
}

function Map() {
    const { id } = useParams();
    const {mobile} = useMobileRepo(id);
    const {locations} = useLocationsRepo(id);

    return (
        <div>
            <img src={googleMapRender(locations)} alt="Google Map" />

            <MobileNavbar id={id} />
        </div>
    );
}

export default Map;

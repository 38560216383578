import {Link, NavLink, Outlet} from "react-router-dom";
import {Container} from "../services/Container";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import {useState} from "react";
import useAuth from "../services/useAuth";

const container = new Container();


function Navbar() {

    const logout = () => {
        container.logout();
        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
        firebase.auth().signOut().then(function() {
            console.log('Signed Out');
            window.location.href = '/login';
        }, function(error) {
            console.error('Sign Out Error', error);
        });
    }

    const {user} = useAuth()

    return (
        <div>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#"><i className="bi bi-shield-fill-check"  style={{color: "green"}}></i> Family Shield</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        {user && <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to="/mobiles" className="nav-link">Devices</Link>
                            </li>
                            <li>
                                Account: {user?.email}
                                <button className="btn btn-outline-primary" onClick={logout}>Logout</button>
                            </li>
                        </ul>}
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to="/login" className="nav-link">Login</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <Outlet/>
        </div>
    )
}

export default Navbar;

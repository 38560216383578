import FirebaseCollection from "./FirebaseCollection";
import {useEffect, useState} from "react";
import useAuth from "./useAuth";


const useMobilesRepo = () => {
    const [mobiles, setMobiles] = useState([]);
    const {user} = useAuth()

    useEffect(() => {
        if (!user || !user.uid) {
            return
        }

        const collection = new FirebaseCollection(user.uid)
            .getCollection("view_mobiles")

        return collection.listenForUpdates(list => {
            // filter list by "hidden" field

            console.log("Mobiles", list)

            list = list.filter(item => !item.hidden)
            setMobiles(list)
        })
    }, [user])

    return {mobiles}
}

export default useMobilesRepo;

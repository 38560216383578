import { useState, useEffect } from 'react';
import { onAuthStateChanged, getIdToken } from 'firebase/auth';
import { auth } from './firebaseConfig';

const useAuth = () => {
    const [user, setUser] = useState(localStorage.getItem('user'));
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                const token = await getIdToken(currentUser);
                setUser(currentUser);
                setToken(token);
                localStorage.setItem('user', user)
                localStorage.setItem('token', token)
            } else {
                setUser(null);
                setToken(null);
            }
            setLoading(false);
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    return { user, token, loading };
};

export default useAuth;
import {NavLink, useParams} from "react-router-dom";
import useMobileRepo from "../services/useMobileRepo";
import MobileNavbar from "./MobileNavbar";

function Mobile() {
    const { id } = useParams();
    const {mobile} = useMobileRepo(id);

    console.log(mobile);

    if (!mobile) {
        return <div>Loading...</div>
    }

    return (
        <div>
            <div className="p-2">
                <h2>{mobile.settings.nickname}, {mobile.deviceName}</h2>

                Battery: {mobile.state.batteryLevel}% <br/>
                Last connection: {new Date(Number(mobile.connectedAt)).toLocaleString()} <br/>

                New events: {mobile.newRecords} <br/>

                <ul>
                    <li><NavLink to={`/mobile/${id}/settings`}>Settings</NavLink></li>
                    <li><NavLink to={`/mobile/${id}/events`}>Events</NavLink></li>
                    <li><NavLink to={`/mobile/${id}/map`}>Locations</NavLink></li>
                    <li><NavLink to={`/mobile/${id}/commands`}>Commands</NavLink></li>
                    <li><NavLink to={`/activate/${id}`}>Activate</NavLink></li>
                    <li><NavLink to={`/details/${id}`}>Details</NavLink></li>
                </ul>
            </div>


            <MobileNavbar id={id}/>
        </div>
    );
}

export default Mobile;

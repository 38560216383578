import FirebaseCollection from "./FirebaseCollection";
import {useEffect, useState} from "react";
import useAuth from "./useAuth";


const useLocationsRepo = (id) => {
    const {user} = useAuth()
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        if (!user || !user.uid) {
            return
        }

        const collection = new FirebaseCollection(null)
            .getCollection("locations")
            .where("mobileId", id)
            .limit(200);

        return collection.listenForUpdates(setLocations)
    }, [user])

    return {locations}
}

export default useLocationsRepo;

class FunctionsApi {
    constructor(url, token) {
        this.url = url
        this.token = token
    }

    async getMobiles() {
        return await this.sendRequest("getMobiles")
    }

    async getRecords(mobileId, limit, offset) {
        return await this.sendRequest("getRecords", {mobileId: mobileId, limit: limit, offset: offset})
    }

    async sendCommand(mobileId, command, payload = {}) {
        return this.sendRequest("sendCommand", {mobileId: mobileId, command: command, payload: payload})
    }

    async getActivation(mobileId) {
        return this.sendRequest("getActivation", {mobileId: mobileId})
    }

    async activatePlan(mobileId, planType, recurring, payType) {
        return this.sendRequest("activatePlan", {mobileId: mobileId, planType: planType, recurring: recurring, payType: payType})
    }

    async cancelPlan(mobileId, planId) {
        return this.sendRequest("cancelPlan", {mobileId: mobileId, planId: planId})
    }

    async sendRequest(name, data = {}) {
        data.functionName = name

        try {
            var result = await (await fetch(this.url, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.token}`,
                },
                method: "POST",
                body: JSON.stringify({data: data})
            })).json()

            console.log({functionResult: result})
        } catch (e) {
            throw new Error(`Error connecting to server: ${e.message}`)
        }

        result = result['data']

        if (result.status !== "success") {
            throw new Error(result.message)
        }

        console.log({result: result.result})

        return result.result
    }
}

class ApiError extends Error {
    constructor(status, payload) {
        super(payload['error']);
        this.status = status;
    }
}


export {
    FunctionsApi,
    ApiError,
}

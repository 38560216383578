import {useParams} from "react-router-dom";
import useApiRequest from "../services/useApi";
import useMobileRepo from "../services/useMobileRepo";
import MobileNavbar from "./MobileNavbar";
import {useState} from "react";





function Commands() {
    const {id} = useParams();
    const {mobile} = useMobileRepo(id);
    const [duration, setDuration] = useState(null);

    const { loadData: updateSetting } = useApiRequest("updateSetting", {mobileId: id, key: "recordingDuration" });

    return (
        <div>
            {mobile && (
                <div className="p-2">
                    <h3>{mobile.settings.nickname}, {mobile.deviceName}</h3>

                    <div className="card">
                        <div className="card-header">
                            <h5>Commands</h5>
                        </div>
                        <div className="card-body">
                            <div className="row p-2">
                                <div className="col">
                                    <div className="input-group">
                                        <div className="input-group-text">
                                            <i className="bi bi-mic"></i> Audio recording
                                        </div>
                                        <select className="form-select" aria-label="" name="duration" onChange={event => setDuration(event.target.value)}>
                                            <option selected>Duration</option>
                                            <option value="3">3 minutes</option>
                                            <option value="10">10 minutes</option>
                                            <option value="30">30 minutes</option>
                                            <option value="60">1 hour</option>
                                            <option value="180">3 hours</option>
                                            <option value="600">10 hours</option>
                                        </select>
                                        <button className="btn btn-primary" onClick={() => updateSetting({value: duration})}>
                                             Start
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row p-2">
                                <div className="col">
                                    <button className="btn btn-primary">Ring</button>
                                </div>
                            </div>
                            <div className="row p-2">
                                <div className="col">
                                    <button className="btn btn-primary">Wipe</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        <MobileNavbar id={id} />
    </div>
    );
}


export default Commands;
import useApiData from "../services/useApiData";
import {useParams} from "react-router-dom";
import useApi from "../services/useApi";
import MobileNavbar from "./MobileNavbar";

function MobilePlan() {
    const {id} = useParams();
    const { data, loading, error } = useApiData("mobileDetails", {mobileId: id});
    const { loadData: cancelPlan } = useApi("cancelPlan", data ? {planId: data.mobile.plan.id} : null);

    if (loading) {
        return <div>Loading data...</div>;
    }

    if (error) {
        return <div>Error loading data: {error.message}</div>;
    }

    if (!data) {
        return <div>Not loaded</div>;
    }

    const plan = data.mobile.plan
    const active = plan.expired ? "Expired" : (plan.recurring ? "Active" : new Date(plan.endsAt).toLocaleString())

    return (
        <div>
            <table className="table">
                <tbody>
                    <tr><td>Device</td><td>{data.mobile.deviceName}</td></tr>
                    <tr><td>Version</td><td>{data.mobile.appVersion}</td></tr>
                    <tr><td>Package</td><td>{data.mobile.packageName}</td></tr>
                    <tr><td>lastConnect</td><td>{data.mobile.lastConnect}</td></tr>
                </tbody>

                <tbody>
                    <tr>
                        <td>Plan</td>
                        <td>{active}</td>
                    </tr>
                    <tr>
                        <td>Next payment</td>
                        <td>?</td>
                    </tr>
                    {plan.recurring && <tr>
                        <td>Stop autopayment</td>
                        <td><button onClick={cancelPlan} className="btn btn-danger">Cancel</button></td>
                    </tr>}
                </tbody>

            </table>
            <MobileNavbar id={id}/>
        </div>
);}

export default MobilePlan;
